<template>
  <ScrollHeader :threshold="100" :hide-delay="200" :show-delay="50" v-if="isShowHeader">
    <Header :nav-class="scrollY > 0 ? 'bg-dark' : 'bg-transparent'" />
  </ScrollHeader>

  <First />
  <!-- <News /> -->
  <Hashkey></Hashkey>
  <Vision />
  <!-- <Join /> -->
  <Outro />
  <Partners />
  <Engage />
  <Footer />
</template>

<script setup>
import Header from './Header.vue'
import { onMounted, ref, onUnmounted } from 'vue'
import { setRootFontSize } from '@/utils'
import First from './First.vue'
import Vision from './Vision.vue'
import Outro from './Outro.vue'
import Partners from './Partners.vue'
import Footer from './Footer.vue'
import Engage from './Engage.vue'
import ScrollHeader from '@/components/ScrollHeader.vue'
import Join from './Join.vue'
import News from './News.vue'
import Hashkey from './Hashkey.vue'
const scrollY = ref(0)
const isShowHeader = ref(true)

const handleScroll = () => {
  const modalElement = document.querySelector('#modal-phone')
  isShowHeader.value = !modalElement
  scrollY.value = window.scrollY
}

onMounted(() => {
  setRootFontSize()
  window.addEventListener('scroll', handleScroll)
  window.addEventListener('resize', setRootFontSize)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  window.removeEventListener('resize', setRootFontSize)
})
</script>
