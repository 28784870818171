<template>
  <Carousel autoplay arrows>
    <img class="hashkey-pc" src="../../assets/bitget-h5.jpg" alt="" @click="goBitget" />
    <img class="hashkey-pc" src="../../assets/hashkey-h5.jpg" alt="" @click="goValidator" />
    <img class="hashkey-pc" src="../../assets/hackathon-h5.png" alt="" @click="goHackathon" />
  </Carousel>
</template>
<script setup>
import { Carousel } from 'ant-design-vue'
function goValidator() {
  window.open('https://btcyield.io/validators/hashkey.sat')
}
function goHackathon() {
  window.open('/events')
}
function goBitget() {
  window.open(
    'https://share.bwb.global/en/tools/taskPage?uuid=9bd4525c-9dd2-41d8-82d7-65ec2547bbd4&_nocache=true&_needChain=eth'
  )
}
</script>
<style scoped>
.hashkey-pc {
  width: 358px !important;
  height: 84px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin: 60px 0px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
