<template>
  <component :is="currentComponent" :screenWidth="screenWidth" />
</template>

<script setup>
import { computed, onMounted, onBeforeUnmount, ref } from 'vue'
import PC from './views/PC/index.vue'
import Phone from './views/Phone/index.vue'
import { isMobile } from './utils'

import { pageview } from 'vue-gtag'
// 部署 +2
const isMobileDevice = ref(false)

const currentComponent = computed(() => (isMobileDevice.value ? Phone : PC))
const screenWidth = ref(window.innerWidth)

const checkDevice = () => {
  const currentWidth = window.innerWidth
  isMobileDevice.value = currentWidth <= 768 || isMobile()
  screenWidth.value = window.innerWidth

  console.log(currentWidth, 'currentWidth')
  if (currentWidth > 768) {
    document.documentElement.style.fontSize = '16px'
  }
}

onMounted(() => {
  checkDevice()
  window.addEventListener('resize', checkDevice)
  pageview('/')
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkDevice)
})
</script>
<style lang="scss" scoped></style>

<style lang="scss">
@import 'styles/reset';
@import 'styles/variables';
@import '@exsat/common/styles/fonts';
@import 'styles/grid';
@import 'styles/card';
@import 'styles/badge';
@import 'styles/form';
@import 'styles/button';
@import 'styles/table';
@import 'styles/toast';
@import 'styles/utils';

@font-face {
  src: url('@/assets/ChakraPetch-SemiBold.ttf');
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 600;
}

body {
  background-color: #000;
  color: #fff;
  font-family: var(--font-family-inter);
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 1rem;
  margin-top: 0;
}

h1 {
  font-size: var(--heading-1);
  line-height: 1.2;
}

h2 {
  font-size: var(--heading-2);
  line-height: 1.2;
}

h3 {
  font-size: var(--heading-3);
  line-height: 1.2;
}

h4 {
  font-size: var(--heading-4);
}

h5 {
  font-size: var(--font-md);
}

a {
  color: currentColor;
  transition: 0.3s;
  text-decoration: underline;

  &.disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.8;
  }

  &:hover,
  &:focus,
  &:active {
    color: currentColor;
    opacity: 0.75;
    text-decoration: underline;
  }

  &.white {
    text-decoration: none;
  }

  &.muted {
    color: var(--text-gray);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: var(--text-white);
    }
  }

  &.gray {
    text-decoration: underline;
    color: var(--text-gray);

    &:hover,
    &:focus,
    &:active {
      color: var(--text-white);
    }
  }

  &.orange {
    color: var(--orange);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: rgba(255, 153, 0, 0.6);
    }
  }
}

[hidden] {
  display: none !important;
  visibility: hidden;
}

svg {
  max-width: 100%;
}

img {
  max-width: 100%;
}

.ant-tooltip {
  color: var(--bg-secondary-color);
  --antd-arrow-background-color: var(--bg-secondary-color);
  font-size: var(--font-sm);
  line-height: 1.5;

  .ant-tooltip-inner {
    background-color: var(--bg-secondary-color);
    padding: var(--spacing-lg);
  }
}

.tooltip-title {
  text-decoration: 2px dotted underline;
  text-decoration-color: #666;
}
</style>
